import React, { FunctionComponent } from 'react'

export interface PuxTrumbowygProps {
    content: PuxTrumbowygBodyType
}

export interface PuxTrumbowygBodyType {
    html: string
}

const PuxTrumbowyg: FunctionComponent<PuxTrumbowygProps> = ({ content }) => {
    return (
        <div
            className="trumbowyg-content-wrap"
            dangerouslySetInnerHTML={{ __html: content?.html?.replace(/font-size: 1rem;/g, ``) }}
        ></div>
    )
}

export default PuxTrumbowyg
