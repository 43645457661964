/**
 * PuxMetaTags component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import NovaLight from '../../fonts/webfonts/340FFF_5_0.woff2'
import NovaRegular from '../../fonts/webfonts/340FFF_8_0.woff2'
import NovaBlack from '../../fonts/webfonts/340FFF_2_0.woff2'
import NovaBold from '../../fonts/webfonts/font.woff2'

import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import { PuxTrumbowygBodyType } from './pux-trumbowyg'
import { getLocalizedData } from '../../utils/localeURL'
import { MediaSelectorBody } from '../../types/widget.types'

const localizedConfigData = getLocalizedData(process.env.LOCALE ?? ``, process.env.PROJECT ?? ``)
const activeEnv = process.env.GATSBY_ACTIVE_ENV ?? ``

export interface PuxMetaTagsType {
  metaTagsTitle: PuxTrumbowygBodyType
  metaTagsRobots: string
  metaTagsCanonicalUrl: PuxTrumbowygBodyType
  metaTagsKeywords: PuxTrumbowygBodyType
  metaTagsDescription: PuxTrumbowygBodyType
  metaTagsOGTitle: PuxTrumbowygBodyType
  metaTagsOGDescription: PuxTrumbowygBodyType
  metaTagsOGImage: MediaSelectorBody
  metaTagsImage: MediaSelectorBody
}

const PuxMetaTags: FunctionComponent<PuxMetaTagsType> = (props) => (
  <Helmet htmlAttributes={{ lang: localizedConfigData.isoCode }}>
    {props.metaTagsTitle?.html && <title>{props.metaTagsTitle.html}</title>}

    {props.metaTagsRobots && <meta name="robots" content={props.metaTagsRobots} />}

    <meta http-equiv="content-language" content={localizedConfigData.isoCode} />

    {props.metaTagsCanonicalUrl?.html && <link rel="canonical" href={props.metaTagsCanonicalUrl.html} />}

    {props.metaTagsDescription?.html && (
      <meta name="description" content={props.metaTagsDescription.html.replace(/(<([^>]+)>)/gi, ``)} />
    )}

    {props.metaTagsKeywords?.html && <meta name="keywords" content={props.metaTagsKeywords.html} />}

    {props.metaTagsOGTitle?.html && <meta property="og:title" content={props.metaTagsOGTitle.html} />}

    {props.metaTagsOGDescription?.html && (
      <meta property="og:description" content={props.metaTagsOGDescription.html.replace(/(<([^>]+)>)/gi, ``)} />
    )}

    {props.metaTagsOGImage?.resizePaths?.length && <meta property="og:image" content={props.metaTagsOGImage.resizePaths[0]} />}
    {!props.metaTagsOGImage?.resizePaths?.length && <meta property="og:image" content="/og-default.png" />}

    <link rel="preload" as="font" href={NovaLight} type="font/woff2" crossOrigin="anonymous" />
    <link rel="preload" as="font" href={NovaRegular} type="font/woff2" crossOrigin="anonymous" />
    <link rel="preload" as="font" href={NovaBlack} type="font/woff2" crossOrigin="anonymous" />
    <link rel="preload" as="font" href={NovaBold} type="font/woff2" crossOrigin="anonymous" />
  </Helmet>
)

export default PuxMetaTags

// PuxMetaTags.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// }

// PuxMetaTags.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }
